const phasesNames = Object.freeze({
  PHASE_A: { urlKey: "a", key: "phase_a", name: "contact", visiblePartner: false },
  PHASE_B: { urlKey: "b", key: "phase_b", name: "tarification", visiblePartner: false },
  PHASE_1: {
    urlKey: "1",
    key: "phase_1",
    name: "acceptée",
    visiblePartner: true,
  },
  PHASE_2: {
    urlKey: "2",
    key: "phase_2",
    name: "dossier administratif",
    visiblePartner: true,
    children: {
      PHASE_2_1: {
        urlKey: "2-1",
        key: "phase_2_1",
        name: "informations",
        visiblePartner: true,
      },
      PHASE_2_2: {
        urlKey: "2-2",
        key: "phase_2_2",
        name: "documents",
        visiblePartner: true,
      },
    },
  },
  PHASE_3: {
    urlKey: "3",
    key: "phase_3",
    name: "intervenants",
    visiblePartner: true,
    children: {
      PHASE_3_1: {
        urlKey: "3-1",
        key: "phase_3_1",
        name: "intervenants techniques",
        visiblePartner: true,
      },
      PHASE_3_2: { urlKey: "3-2", key: "phase_3_2", name: "matériaux", visiblePartner: true },
      PHASE_3_3: {
        urlKey: "3-3",
        key: "phase_3_3",
        name: "entreprises",
        visiblePartner: true,
      },
    },
  },
  PHASE_4: { urlKey: "4", key: "phase_4", name: "signature", visiblePartner: true },
  PHASE_5: { urlKey: "5", key: "phase_5", name: "validation", visiblePartner: true },
  PHASE_6: { urlKey: "6", key: "phase_6", name: "contrats", visiblePartner: true },
  PHASE_7: {
    urlKey: "7",
    key: "phase_7",
    name: "réception",
    visiblePartner: true,
    children: {
      PHASE_7_1: {
        urlKey: "7-1",
        key: "phase_7_1",
        name: "documents réceptions",
        visiblePartner: true,
      },
      PHASE_7_2: {
        urlKey: "7-2",
        key: "phase_7_2",
        name: "Intervenants finaux",
        visiblePartner: true,
      },
      PHASE_7_3: {
        urlKey: "7-3",
        key: "phase_7_3",
        name: "signatures finales",
        visiblePartner: true,
      },
    },
  },
  PHASE_8: { urlKey: "8", key: "phase_8", name: "finalisé", visiblePartner: true },
});

const clientPhasesNames = Object.freeze({
  // 1
  PHASE_1: {
    urlKey: "1",
    key: "phase_1",
    name: "Offre",
    visiblePartner: true,
  },
  // 2, 3, 4
  PHASE_2: {
    urlKey: "2",
    key: "phase_2",
    name: "Dossier en cours",
  },
  // 5, 6
  PHASE_3: {
    urlKey: "3",
    key: "phase_3",
    name: "Dossier validé",
  },
  // 7
  PHASE_4: {
    urlKey: "4",
    key: "phase_4",
    name: "Dossier de réception",
  },
  // 8
  PHASE_5: {
    urlKey: "5",
    key: "phase_5",
    name: "Dossier finalisé",
  },
});

const mappedClientPhasesNames = Object.freeze({
  [clientPhasesNames.PHASE_1.urlKey]: [phasesNames.PHASE_1.urlKey, phasesNames.PHASE_1.key],
  [clientPhasesNames.PHASE_2.urlKey]: [
    phasesNames.PHASE_2.children.PHASE_2_1.urlKey,
    phasesNames.PHASE_2.children.PHASE_2_1.key,
    phasesNames.PHASE_2.children.PHASE_2_2.urlKey,
    phasesNames.PHASE_2.children.PHASE_2_2.key,
    phasesNames.PHASE_3.children.PHASE_3_1.urlKey,
    phasesNames.PHASE_3.children.PHASE_3_1.key,
    phasesNames.PHASE_3.children.PHASE_3_2.urlKey,
    phasesNames.PHASE_3.children.PHASE_3_2.key,
    phasesNames.PHASE_3.children.PHASE_3_3.urlKey,
    phasesNames.PHASE_3.children.PHASE_3_3.key,
    phasesNames.PHASE_4.urlKey,
    phasesNames.PHASE_4.key,
  ],
  [clientPhasesNames.PHASE_3.urlKey]: [
    phasesNames.PHASE_5.urlKey,
    phasesNames.PHASE_5.key,
    phasesNames.PHASE_6.urlKey,
    phasesNames.PHASE_6.key,
  ],
  [clientPhasesNames.PHASE_4.urlKey]: [
    phasesNames.PHASE_7.children.PHASE_7_1.urlKey,
    phasesNames.PHASE_7.children.PHASE_7_1.key,
    phasesNames.PHASE_7.children.PHASE_7_2.urlKey,
    phasesNames.PHASE_7.children.PHASE_7_2.key,
    phasesNames.PHASE_7.children.PHASE_7_3.urlKey,
    phasesNames.PHASE_7.children.PHASE_7_3.key,
  ],
  [clientPhasesNames.PHASE_5.urlKey]: [phasesNames.PHASE_8.urlKey, phasesNames.PHASE_8.key],
});

const missions = Object.freeze({
  PLANS: { key: "plans", name: "Plans" },

  G0: { key: "g0", name: "(G0) Exécution de sondages, essais et mesures géotechniques" },
  G1: { key: "g1", name: "(G1) Etude géotechnique préliminaire de site" },
  G3: { key: "g3", name: "(G3) Etude et suivi géotechnique d’exécution" },
  G5: { key: "g5", name: "(G5) Diagnostic géotechnique" },
  G51: {
    key: "g51",
    name: "(G51) Diagnotic géotechnique avant,pendant ou après construction en l'absence de sinistre",
  },
  G2_AVP: {
    key: "g2_avp",
    name: "(G2 AVP) Etude géotechnique de conception Phase Avant Projet",
  },
  G2: { key: "g2", name: "(G2) Etude géotechnique de conception Phase Projet (G2 PRO)" },
  G4: { key: "g4", name: "(G4) Supervisation de l'étude d'execution" },
  G52: { key: "g52", name: "(G52) Diagnotic géotechnique sur un ouvrage sinistré" },

  M1: { key: "m1", name: "Permis de construire" },
  M2: { key: "m2", name: "Plans" },
  M3: { key: "m3", name: "Mission Conception" },
  M4: { key: "m4", name: "Mission réalisation" },
  M9: { key: "m9", name: "Mission Complète" },

  BET_BA: { key: "bet_ba", name: "Etude Structures" },
  BET_TH: { key: "bet_th", name: "Etude Thermique" },
  FLUIDS: { key: "fluids", name: "Fluides (climatisation, électricité, )" },
  ACOUSTIC: { key: "acoustic", name: "Acoustique" },
  BET: { key: "bet", name: "Autres types de BET, à préciser" },
  ECN: { key: "ecn", name: "Mission économiste de la construction" },
  OPC: { key: "opc", name: "Ordonnancement Pilotage Coordination" },

  ESQ: { key: "esq", name: "Esquisse (construction neuve)" },
  EP: { key: "ep", name: "Etudes Préliminaires (infrastructures)" },
  DIA: { key: "dia", name: "Diagnostic (réhabilitation)" },
  APS: { key: "aps", name: "Avant-Projet sommaire" },
  APD: { key: "apd", name: "Avant Projet Définitif" },
  PRO: { key: "pro", name: "Etude de Projet" },
  ACT: { key: "act", name: "Assistance aux Contrats de Travaux" },
  EXE: { key: "exe", name: "Etudes d’Exécution" },
  DET: { key: "det", name: "Direction de l’exécution des Travaux" },
  AOR: { key: "aor", name: "Assistance aux Opérations de Réception" },

  L: {
    key: "l",
    name: "(L) relative à la solidité des ouvrages et des éléments d’équipement indissociables",
  },
  LP: {
    key: "lp",
    name: "(LP) relative à la solidité des ouvrages et des éléments d’équipement dissociables et indissociables",
  },
  S: {
    key: "s",
    name: "S (SH - SEI -STI) relatives à la sécurité des personnes dans les habitations, les ERP et IGH, les bâtiments tertiaires et industriels",
  },
  TH: {
    key: "th",
    name: "(TH) relative à l'isolation thermiques des constructions",
  },
  PH: {
    key: "ph",
    name: "(PH) relative à l'isolation acoustique des constructions",
  },
  H: {
    key: "H",
    name: "(H) relatives au respect des prescriptions réglementaires pour l’accès des personnes handicapées et des brancards",
  },
  PS: {
    key: "ps",
    name: "(PS) relative la sécurité des personnes dans les constructions en cas de séisme",
  },
  P1: {
    key: "p1",
    name: "(P1) Mission relative à la solidité des éléments d’équipement non indissociablement liés.",
  },
  F: {
    key: "f",
    name: "(F) Mission relative au fonctionnement des installations.",
  },
  BRD: {
    key: "brd",
    name: "(BRD) Mission relative au transport des brancards dans les constructions.",
  },
  LE: {
    key: "le",
    name: "(LE) vise la solidité des constructions existantes",
  },
  AV: {
    key: "av",
    name: "(AV) relative à la solidité des avoisinants",
  },
  GTB: {
    key: "gtb",
    name: "(GTB) Mission relative à la gestion technique du bâtiment.",
  },
  ENV: {
    key: "env",
    name: "(ENV) Mission relative à l’environnement.",
  },
  HYS: {
    key: "hys",
    name: "(HYS) Mission relative à l’hygiène et à la santé dans les bâtiments.",
  },
  CO: {
    key: "co",
    name: "(CO) Mission relative à la coordination des missions de contrôle.",
  },
  PV: {
    key: "pv",
    name: "(PV) Relative au récolement des procès-verbaux",
  },
  OTHER_TEHNICAL_CONTROLLER: {
    key: "other_tehnical_controller",
    name: "Autres à Préciser :",
  },
  DEM: {
    key: "dem",
    name: "(DEM) Mission relative à la solidité des ouvrages avoisinants en cas de démolition d’ouvrage existants.",
  },
  pool_gros_oeuvre: { key: "pool_gros_oeuvre", name: "Gros œuvre" },
  pool_sealing: { key: "pool_sealing", name: "Etanchéité" },
});

const lots = Object.freeze({
  DEMOLITION: { key: "demolition", name: "Démolition", position: 1 },
  EARTHWORK: { key: "earthwork", name: "Terrassement", position: 2 },
  VRD: { key: "vrd", name: "VRD", position: 3 },
  FOUNDATIONS: { key: "foundations", name: "Fondations", position: 4 },
  MASONRY: { key: "masonry", name: "Maçonnerie, gros œuvre", position: 5 },
  FRAMEWORK: { key: "framework", name: "Charpente", position: 6 },
  ROOFING: { key: "roofing", name: "Couverture, Zinguerie", position: 7 },
  SEALING: { key: "sealing", name: "Etanchéité", position: 8 },
  EXTERIOR_FURNISHINGS: {
    key: "exterior_furnishings",
    name: "Menuiseries extérieures",
    position: 9,
  },
  INTERIOR_FURNISHINGS: {
    key: "interior_furnishings",
    name: "Menuiserie intérieure",
    position: 10,
  },
  SANITARY_PLUMBING: { key: "sanitary_plumbing", name: "Plomberie, sanitaire", position: 11 },
  HEATING_AIR_CONDITIONING_VMC: {
    key: "heating_air_conditioning_vmc",
    name: "Chauffage, clim, VMC",
    position: 12,
  },
  FLOOR_COVERINGS: {
    key: "floor_coverings",
    name: "Revêtements de sols (carrelage, parquet)",
    position: 13,
  },
  FACADE_EXTERIOR_CLADDING: {
    key: "facade_exterior_cladding",
    name: "Revêtements façades, extérieurs",
    position: 14,
  },
  COATING: { key: "coating", name: "Revêtement, Peintures intérieures", position: 15 },
  PLASTERING: { key: "plastering", name: "Plâtrerie, Cloison, Isolation", position: 16 },
  ELECTRICITE: { key: "electricite", name: "Electricité", position: 17 },
  METALWORK: { key: "metalwork", name: "Serrurerie Métallerie", position: 18 },

  POOL_GROS_OEUVRE: { key: "pool_gros_oeuvre", name: "Piscine - Gros œuvre", position: 19 },
  POOL_SEALING: { key: "pool_sealing", name: "Piscine - Etanchéité", position: 20 },
});

const metiers = Object.freeze({
  ARCHITECT: { key: "architect", name: "Architecte", position: 1 },
  PROJECT_MANAGER: { key: "project_manager", name: "Un maître d'oeuvre", position: 2 },
  SOIL_STUDY: { key: "soil_study", name: "Une étude de sol", position: 3 },
  TECHNICAL_CONTROL: { key: "technical_control", name: "Un contrôleur technique", position: 6 },
  STUDY_OFFICE: { key: "study_office", name: "Un bureau d'étude techniques", position: 4 },
  OTHER_TEHNICAL_CONTRIBUTORS: {
    key: "other_technical_contributors",
    name: "Autres intervenants techniques",
    position: 7,
  },
});

const phasesDocs = Object.freeze({
  NATIONAL_IDENTITY_CARD: {
    key: "national_identity_card",
    name: "Carte nationale d’identité du souscripteur signataire",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // phase 2.3 - if MOA = professionnel
  KBIS: {
    key: "kbis",
    name: "K-bis de la personne morale",
    description: "daté de moins de 3 mois (le cas échéant)",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },

  BUILDING_PERMIT_ORDER: {
    key: "building_permit_order",
    name: "Arrêté de permis de construire au nom du souscripteur",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // phase 2.3 if Construction Neuve, Extension surface créé +20m²
  CERTIFICATE_NON_OBJECTION: {
    key: "certificate_non_objection",
    name: "Certificat de non opposition (permis tacite)",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // phase 2.3 if Extension, Réhabilitation, Rénovation
  AGREEMENT_PROPR_DECLARATION_WORK: {
    key: "agreement_prior_declaration_work",
    name: "Accord Déclaration Préalable de Travaux",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // phase 2.3 (FOR 2.3 if Date d’Ouverture de Chantier > today - 3mois)
  CERTIFICATE_NO_CLAIMS: {
    key: "certificate_no_claims",
    name: "Attestation de non sinistralité",
    description: "Pour les chantiers déjà ouverts ; actualisée si date passée",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },

  QUESTIONNAIRE: {
    key: "questionnaire",
    name: "Questionnaire d'étude",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // phase 2.3
  DEPOSIT_PROOF: {
    key: "deposit_proof",
    name: "Justificatif de règlement de l'acompte",
    description: "Avis de virement ou copie de chèque",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },

  PC_DP: {
    key: "pc_dp",
    name: "PC / DP",
    description: "Permis de Construire ou Déclaration Préalable de Travaux: signée par la mairie",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // phase 2.3
  DOC: {
    key: "doc",
    name: "Déclaration d'Ouverture de Chantier",
    description:
      "Déclaration d'ouverture de chantier (DOC) complété, tamponnée et signée par la Mairie ; même non validée en mairie, Attention la date validée en mairie devra être identique à celle indiquée.",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // phase 2.3 - if constructionType == NEW CONSTRUCTION
  PLANS: {
    key: "plans",
    name: "Plans",
    description: "Situation, masse, coupes, façades, distribution des pièces",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  SITE_PLAN: {
    key: "site_plan",
    name: "Plan(s) de situation",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
    active: false,
  },
  FACADE_ROOF_PLANS: {
    key: "facade_roof_plans",
    name: "Plan(s) de façades",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
    active: false,
  },
  GROUND_PLANS: {
    key: "ground_plans",
    name: "Plan(s) de masses",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
    active: false,
  },
  LAND_SECTION_PLAN: {
    key: "land_section_plan",
    name: "Plan(s) de coupes",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
    active: false,
  },
  DISTRIBUTION_PLANS: {
    key: "distribution_plans",
    name: "Plan(s) de distribution des pièces",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
    active: false,
  },
  // phase 2.3 - if constructionType != NEW CONSTRUCTION
  PLANS_BEFORE_AND_AFTER: {
    key: "plans_before_and_after",
    name: "Plans",
    description: "Situation, masse, coupes, façades, distribution des pièces avant et après",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
    active: false,
  },
  // phase 2.3 - if pool = 0
  CERTIFICATE_SWIMMING_POOL: {
    key: "certificate_swimming_pool",
    name: "Attestation d’exclusion de la piscine",
    description:
      "Merci de nous fournir une attestation sur l’honneur MANUSCRITE et signée sur l'exemple du modèle ci-contre.",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  CERTIFICATE_SWIMMING_PHOTOVOLTAIC_PANNELS: {
    key: "certificate_swimming_photovoltaic_panels",
    name: "Attestation d’exclusion de la panneaux photovoltaïques",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // phase 2.3 - ONLY for Leader MIC, if slope > 15%
  CERTIFICATE_SLOPE: {
    key: "certificate_slope",
    name: "Attestation du pourcentage de pente du terrain établie par un professionnel habilité",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // phase 2.3 (on 2.3 -> only if Réhabilitation, Rénovation)
  DOC_CHANTIER_ACTUALISE: {
    key: "doc_chantier_actualise",
    name: "Déclaration d’ouverture de chantier type",
    description:
      "Date de fin de chantier actualisée, si date dépassée et que le chantier n'est pas réceptionné.",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // Phase 4
  SIGNED_QUESTIONNAIRE: {
    key: "signed_questionnaire",
    name: "Questionnaire informatique",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // Phase 4
  INSURANCE_PROPOSAL: {
    key: "insurance_proposal",
    name: "Proposition d'assurance",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // Phase 4
  INFORMATION_SHEET: {
    key: "information_sheet",
    name: "Fiche d'information et de conseil",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // phase 5
  PAYMENT_PROOF: {
    key: "payment_proof",
    name: "Justificatif de paiement",
    description: "Avis de virement ou copie de chèque",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // phase 7.3
  PAYMENT_PROOF_FINAL: {
    key: "payment_proof_final",
    name: "Règlement de la prime complémentaire",
    description: "Avis de virement ou copie de chèque",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // phase 6 -> inactive
  PAYMENT_RECEIPT: {
    key: "payment_receipt",
    name: "Quittance de règlement",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
    active: false,
  },

  CONTRACT: {
    key: "contract",
    name: "Conditions particulières",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // phase 6
  CERTIFICATE_PROVISIONAL: {
    key: "certificate_provisional",
    name: "Attestation prévisionnelle",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // phase 6
  INSURER_RETURN: {
    key: "insurer_return",
    name: "Validation de l'assureur",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // phase 6
  APPEL_PRIME: {
    key: "appel_prime",
    name: "Appel de prime",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // phase 6
  CONTRACT_SIGNED: {
    key: "special_conditions_signed",
    name: "Conditions particulières signées",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // phase 8
  DAMAGE_AMENDMENT: {
    key: "damage_amendment",
    name: "Avenant de dommage ouvrage définitif",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // phase 8
  FINAL_CERTIFICATE: {
    key: "final_certificate",
    name: "Attestation définitive",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // phase 7.2
  DAACT: {
    key: "daact",
    name: "Déclaration Attestant l’Achèvement et de la Conformité des travaux CERFA",
    description: "validée, tamponnée et signée par la mairie",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // phase 7.2
  DGD: {
    key: "dgd",
    name: "Questionnaire de reception du chantier",
    description:
      "Merci de compléter ce questionnaire pour déclarer la réception du chantier et définir le décompte définitif des travaux.",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // Phase 7.3
  PV_RECEPTION: {
    key: "pv_reception",
    name: "Procès verbaux de réception",
    description: "À la même date pour chaque intervenant",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // Phase 7.3
  PV_REPORTS: {
    key: "pv_reports",
    name: "Procès verbaux de levée de réserve",
    description: "pour chaque intervenant",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // Phase 2.3
  EXCLUDE_T14: {
    key: "exclude_t14",
    name: "Attestation d’exclusion du lot T-14 revêtement extérieur, façade",
    description: "",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // Phase 2.3
  EXCLUDE_SECOND: {
    key: "exclude_second",
    name: "Attestation de retrait des/du lots(s) de second œuvre",
    description: "",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // Phase 2.3
  DEPOSIT_PAYMENT_RECEIPT: {
    key: "deposit_payment_receipt",
    name: "Facture de l'acompte",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
    active: true,
  },
  DOC_PHASE_7: {
    key: "doc_phase_7",
    name: "Déclaration d'ouverture de chantier",
    description:
      "Déclaration d'ouverture de chantier (DOC) complété, tamponnée et signée par la Mairie",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },

  PLANS_AMENDING_BUILDING_PERMIT: {
    key: "plans_amending_building_permit",
    name: "Plans du permis de construire modificatif",
    description: "Situation, masse, coupes, façades, distribution des pieces avant et apres",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  ORDER_AMENDING_BUILDING_PERMIT: {
    key: "order_amending_building_permit",
    name: "Arrête du permis de construire modificatif",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  EXCLUSION_CERTIFICATE: {
    key: "exclusion_certificate",
    name: "Attestation d’exclusion de lot",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },

  CERTIFICATE_COMPLIANCE: {
    key: "certificate_compliance",
    name: "Attestation de conformité à la règlementation thermique ",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  // Phase 7.3
  FINAL_VALIDATION_INSURER: {
    key: "final_validation_insurer",
    name: "Validation définitive par l’assureur",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
    active: true,
  },
  APPEL_PRIME_COMPLEMENTARY: {
    key: "appel_prime_complementary",
    name: "Appel de prime complémentaire",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
    active: false,
  },
  TABLE_STAKEHOLDERS: {
    key: "table_stakeholders",
    name: "Tableau récapitulatif des intervenants",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
    active: false,
  },
  FINAL_COUNT: {
    key: "final_count",
    name: "Décompte définitif",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
    active: true,
  },

  // phase 2.2 - DUNE-ZURICH
  KBIS_PROFESSIONAL: {
    key: "kbis_professional",
    name: "K-bis du syndic professionnel",
    description: "daté de moins de 3 mois",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  IDENTITY_CARD_REPRESENTATIVE_PROFESSIONAL: {
    key: "identity_card_representative_professionnel",
    name: "Pièces d'identité du représentant syndic professionnel",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  IDENTITY_CARD_PROJECT_OWNER: {
    key: "identity_card_project_owner",
    name: "Pièce d'identité du maitre d'ouvrage",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  SERVICEABILITY_CERTIFICATE: {
    key: "serviceability_certificate",
    name: "Attestation de viabilisation",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  CERTIFICATE_WITHOUT_FACADE_CLADDING: {
    key: "certificate_without_facade_cladding",
    name: "Attestation sans revêtement de façade",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  CLOSED_COVERED_CERTIFICATE: {
    key: "closed_covered_certificate",
    name: "Attestation clos/couvert",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  TARIFICATEUR: {
    key: "tarificateur",
    name: "TARIFICATEUR",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
    active: false,
  },
  DECLARATION_POSTPONEMENT_END_CONSTRUCTION_DATE: {
    key: "declaration_postponement_end_construction_date",
    name: "DECLARATION DE REPORT DATE DE FIN DE CHANTIER",
    description:
      "Si votre date de réception prévisionnelle est dépassée. Vous devez nous informer des raisons de ce retards et nous indiquer une nouvelle date de réception prévisionnelle.",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  DECLARATION_HONOUR_RECEIPT_NOT_PRONOUNCED: {
    key: "declaration_honour_receipt_not_pronounced",
    name: "Attestation sur l'honneur réception non prononcée",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  PV_GENERAL_MEETING_CO_OWNERS_AUTHORIZING_WORK: {
    key: "pv_general_meeting_co_owners_authorizing_work",
    name: "Procès Verbal de l'Assemblée Générale de Copropriété autorisant les travaux",
    description: "Uniquement si l'immeuble est en copropriété",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  CERTIFICATE_NO_CLAIMS_PHASE71: {
    key: "certificate_no_claims_phase71",
    name: "Attestation de non sinistralité",
    description: "Pour les chantiers déjà ouverts ; actualisée si date passée",
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },

  NOTARIAL_DEED_PRONOUNCING_SALE_PROPERTY: {
    key: "notarial_deed_pronouncing_sale_property",
    name: "Acte notarié prononçant la vente du bien",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  KBIS_LESS_3_MONTHS: {
    key: "kbis_less_3_months",
    name: "Kbis de moins de 3 mois",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  IDENTITY_DOCUMNET_NEW_PROJECT_OWNER: {
    key: "identity_document_new_project_owner",
    name: "Pièce d’identité du nouveau maitre d’ouvrage ou du représentant en cas de personne morale",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
  RAPPORT_GEORISK: {
    key: "rapport_georisk",
    name: "Rapport de risques Géorisques",
    description: null,
    default: true,
    endPhaseValidation: true,
    endDossierValidation: false,
  },
});

const phase23GenerableDocuments = Object.freeze([
  phasesDocs.DOC_CHANTIER_ACTUALISE.key,
  phasesDocs.DOC.key,
  phasesDocs.CERTIFICATE_SWIMMING_POOL.key,
  phasesDocs.EXCLUDE_SECOND.key,
  phasesDocs.EXCLUDE_T14.key,
  phasesDocs.CERTIFICATE_SWIMMING_PHOTOVOLTAIC_PANNELS.key,
]);

const phaseValidationStatus = Object.freeze({
  FUTURE: { key: "future", name: "Future" },
  VALID: { key: "valid", name: "Valid" },
  INVALID: { key: "invalid", name: "Invalid" },
});

const trades = Object.freeze({
  ARCHITECT: { key: "architect", name: "Architecte" },
  PROJECT_MANAGER: { key: "project_manager", name: "Maitre d'œuvre" },
  THERMAL_DESIGN_OFFICE: { key: "thermal_design_office", name: "Bureau d'étude thermique" },
  CONCRETE_DESIGN_OFFICE: { key: "concrete_design_office", name: "Bureau d'étude béton" },
  STRUCUTRAL_DESIGN_OFFICE: { key: "structural_design_office", name: "Bureau d'étude techinques" },
  FLOOR_SURVEY_OFFICE: { key: "floor_survey_office", name: "Bureau d'étude de sol" },
  TECHNICAL_CONTROLLER: { key: "technical_controller", name: "Contrôleur techinque" },
});

const missionsArchitectProjectManager = Object.freeze({
  M1: { key: "m1", name: "Permis de construire" },
  M3: { key: "m3", name: "Mission Conception" },
  M4: { key: "m4", name: "Mission réalisation" },
  M9: { key: "m9", name: "Mission Complète" },
});

const missionsTechnicalStudyOffice = Object.freeze({
  BET_BA: { key: "bet_ba", name: "Etude Structures" },
  BET_TH: { key: "bet_th", name: "Etude Thermique" },
  FLUIDS: { key: "fluids", name: "Fluides (climatisation, électricité, )" },
  ACOUSTIC: { key: "acoustic", name: "Acoustique" },
  BET: { key: "bet", name: "Autres à préciser :" },
});

const missionsSoilStudy = Object.freeze({
  G1: { key: "g1", name: "(G1) Etude géotechnique préliminaire de site" },
  G2_AVP: { key: "g2_avp", name: "(G2 AVP) Etude géotechnique de conception Phase Avant Projet" },
  G2: { key: "g2", name: "(G2 PRO) Etude géotechnique de conception Phase Projet" },
  G3: { key: "g3", name: "(G3) Etude et suivi géotechnique d’exécution" },
  G4: { key: "g4", name: "(G4) Supervisation de l'étude d'execution" },
  G51: {
    key: "g51",
    name: "(G51) Diagnotic géotechnique avant,pendant ou après construction en l'absence de sinistre",
  },
  G52: { key: "g52", name: "(G52) Diagnotic géotechnique sur un ouvrage sinistré" },
});

const missionsTechnicalController = Object.freeze({
  L: {
    key: "l",
    name: "(L) relative à la solidité des ouvrages et des éléments d’équipement indissociables",
  },
  LP: {
    key: "lp",
    name: "(LP) relative à la solidité des ouvrages et des éléments d’équipement dissociables et indissociables",
  },
  LE: {
    key: "le",
    name: "(LE) vise la solidité des constructions existantes",
  },
  AV: {
    key: "av",
    name: "(AV) relative à la solidité des avoisinants",
  },
  S: {
    key: "s",
    name: "S (SH - SEI -STI) relatives à la sécurité des personnes dans les habitations, les ERP et IGH, les bâtiments tertiaires et industriels",
  },
  TH: {
    key: "th",
    name: "(TH) relative à l'isolation thermiques des constructions",
  },
  PH: {
    key: "ph",
    name: "(PH) relative à l'isolation acoustique des constructions",
  },
  H: {
    key: "H",
    name: "(H) relatives au respect des prescriptions réglementaires pour l’accès des personnes handicapées et des brancards",
  },
  PS: {
    key: "ps",
    name: "(PS) relative la sécurité des personnes dans les constructions en cas de séisme",
  },
  PV: {
    key: "pv",
    name: "(PV) Relative au récolement des procès-verbaux",
  },
  OTHER_TEHNICAL_CONTROLLER: {
    key: "other_tehnical_controller",
    name: "Autres à Préciser :",
  },
});

const missionsPools = Object.freeze({
  pool_gros_oeuvre: { key: "pool_gros_oeuvre", name: "Gros œuvre" },
  pool_sealing: { key: "pool_sealing", name: "Etanchéité" },
});

const beneficiariesDocuments = Object.freeze([
  phasesDocs.BUILDING_PERMIT_ORDER.key,
  phasesDocs.NOTARIAL_DEED_PRONOUNCING_SALE_PROPERTY.key,
  phasesDocs.KBIS_LESS_3_MONTHS.key,
  phasesDocs.IDENTITY_DOCUMNET_NEW_PROJECT_OWNER.key,
]);

export {
  phasesNames,
  phase23GenerableDocuments,
  clientPhasesNames,
  mappedClientPhasesNames,
  missions,
  lots,
  metiers,
  phasesDocs,
  phaseValidationStatus,
  trades,
  missionsArchitectProjectManager,
  missionsTechnicalStudyOffice,
  missionsSoilStudy,
  missionsTechnicalController,
  missionsPools,
  beneficiariesDocuments,
};
