import axios from "axios";
import links from "@/links";
import qs from "qs";

export default {
  uploadFile(targetedInstanceModel, targetedInstanceId, formData, opts = {}) {
    const {
      isAssurance,
      isDevis,
      devisUid,
      isAdditionalDevis,
      additionalDevisUid,
      isInvoice,
      invoiceUid,
      isProcess,
      processUid,
      parentDevisUid,
      isContractFinal,

      isPhase7,
    } = opts;
    const params = {
      targetedInstanceModel,
      targetedInstanceId,

      // HACK: ..
      isAssurance,
      isDevis,
      devisUid,
      isAdditionalDevis,
      additionalDevisUid,
      isInvoice,
      invoiceUid,
      isProcess,
      processUid,
      parentDevisUid,
      isContractFinal,

      isPhase7,
    };
    return axios.post(`${links.DOSSIER_FILES_LINK()}?${qs.stringify(params)}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  deleteFile(targetedInstanceModel, targetedInstanceId, filename, opts = {}) {
    const {
      isAssurance,
      isDevis,
      devisUid,
      isAdditionalDevis,
      additionalDevisUid,
      isInvoice,
      invoiceUid,
      isProcess,
      processUid,
      parentDevisUid,
      isContractFinal,

      isPhase7,
    } = opts;
    const params = {
      targetedInstanceModel,
      targetedInstanceId,
      filename,

      // HACK: ..
      isAssurance,
      isDevis,
      devisUid,
      isAdditionalDevis,
      additionalDevisUid,
      isInvoice,
      invoiceUid,
      isProcess,
      processUid,
      parentDevisUid,
      isContractFinal,

      isPhase7,
    };
    return axios.delete(`${links.DOSSIER_FILES_LINK()}?${qs.stringify(params)}`);
  },
  getFile(targetedInstanceModel, targetedInstanceId, filename, opts = {}) {
    const { isPhase7 } = opts;
    const params = {
      targetedInstanceModel,
      targetedInstanceId,
      filename,

      isPhase7,
    };
    return axios.get(`${links.DOSSIER_FILES_LINK()}?${qs.stringify(params)}`, {
      responseType: "arraybuffer",
    });
  },
  getTrashedFile(dossierId, filename) {
    const params = {
      dossierId,
      filename,
    };
    return axios.get(`${links.DOSSIER_TRASHED_FILE_LINK()}?${qs.stringify(params)}`, {
      responseType: "arraybuffer",
    });
  },
  getFilePublic(signatureToVerify, targetedInstanceModel, dossierAccessHash, filename, opts = {}) {
    const { isPhase7 } = opts;
    const { originalname } = opts;
    const params = {
      file: filename,
      tim: targetedInstanceModel,
      p7: isPhase7,
      sign: signatureToVerify,
      originalname: originalname,
    };
    window.open(`${links.DOSSIER_FILES_PUBLIC_LINK(dossierAccessHash)}?${qs.stringify(params)}`);
  },

  getAutoGeneratedZipFile(dossierId, opts = {}) {
    const { isPhase7, isAllPhases } = opts;
    const params = {
      dossierId,
      isPhase7,
      isAllPhases,
    };
    return axios.get(`${links.DOSSIER_FILES_AUTO_GENERATED_ZIP_FILE()}?${qs.stringify(params)}`, {
      responseType: "arraybuffer",
    });
  },

  getCsvDossiers(opts = {}) {
    const { search, status, responsible, specialDossiersFilter, insurer } = opts;
    const params = {
      search,
      status,
      responsible,
      specialDossiersFilter,
      insurer,
    };
    return axios.get(`${links.DOSSIER_FILES_EXPORT_CSV_DOSSIERS()}?${qs.stringify(params)}`, {
      responseType: "arraybuffer",
    });
  },

  mergeFilesInOnePdf(targetedInstanceModel, targetedInstanceId, opts = {}) {
    const {
      isAssurance,
      isDevis,
      devisUid,
      parentDevisUid,
      isAdditionalDevis,
      additionalDevisUid,
      isInvoice,
      invoiceUid,
      isProcess,
      processUid,
      isContractFinal,

      isPhase7,
    } = opts;

    const params = {
      targetedInstanceModel,
      targetedInstanceId,

      // HACK: ..
      isAssurance,
      isDevis,
      devisUid,
      parentDevisUid,
      isAdditionalDevis,
      additionalDevisUid,
      isInvoice,
      invoiceUid,
      isProcess,
      processUid,
      isContractFinal,

      isPhase7,
    };
    return axios.post(`${links.DOSSIER_FILES_MERGE_PDFS()}?${qs.stringify(params)}`);
  },

  deleteCommunicationFile(targetedInstanceModel, targetedInstanceId, filename) {
    const params = {
      targetedInstanceModel,
      targetedInstanceId,
      filename,
    };
    return axios.delete(
      `${links.DOSSIER_FILES_DELETE_COMMUNICATION_FILE_LINK()}?${qs.stringify(params)}`
    );
  },
  getExcelStatistics(opts = {}) {
    let { dates, insurers, typeComparison, employee, origin } = opts;
    let params = {
      dates,
      insurers,
      typeComparison,
      employee,
      origin,
    };
    return axios.get(`${links.DOSSIERS_EXPORT_EXCEL_STATISTICS()}?${qs.stringify(params)}`, {
      responseType: "arraybuffer",
    });
  },

  getDevisCommunicationFilePublic(filename) {
    const params = {
      file: filename,
    };
    window.open(`${links.DOSSIER_FILES_DEVIS_COMMUNICATION_PUBLIC_LINK()}?${qs.stringify(params)}`);
  },

  getGeneralSettingFilePublic(filename) {
    const params = {
      file: filename,
    };
    window.open(`${links.DOSSIER_FILES_GENERAL_SETTING_PUBLIC_LINK()}?${qs.stringify(params)}`);
  },

  getInsurerCommunicationFilePublic(filename) {
    const params = {
      file: filename,
    };
    window.open(
      `${links.DOSSIER_FILES_INSURER_COMMUNICATION_PUBLIC_LINK()}?${qs.stringify(params)}`
    );
  },

  getDossierArchivedFilePublic(filename) {
    const params = {
      file: filename,
    };
    window.open(`${links.DOSSIER_FILES_DOSSIER_ARCHIVED_LINK()}?${qs.stringify(params)}`);
  },
};
