import Dossier from "../models/Dossier";
import API from "@/api/dossiers";
import {
  agencyCompanies,
  specialDossiersFilters,
  statuses,
  statusAppelPrime,
} from "@/constants/dossiers";
import User from "../models/User";
import { phasesNames, phaseValidationStatus } from "@/constants/dossier.structure";

function getDefaultState() {
  return {
    activeSearchTerm: "",
    activeDossierStatus: null,
    activeDossierResponsible: null,
    activeSpecialDossiersFilter: null,
    activeInsurer: null,

    hasMoreDossiers: {},
    cursorsForPagination: {},
    totalItems: {},
    totalFilteredItems: {},

    hasInvalidDocuments: false,
    hasInvalidDocumentsToggle: false,

    phaseValidationChanged: false,

    contactHasOnlyOneDossier: false,
    averageTime: {},
    statistics: {},
    urlInviteClient: null,
    subscriptionNotSave: false,
    subscriptionUnsaveChanges: {},

    activeDossierNotificationStatus: null,
    activeDossierNotificationActionType: null,
    activeDossierNotificationPhase: null,
    clientIsActivated: null,
    archivedNotificationLog: false,

    controller: null,
    loadingStatistics: true,
  };
}

export default {
  state() {
    return getDefaultState(); // initial state
  },
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      Object.assign(state, getDefaultState());
    },

    setActiveSearchTerm(state, activeSearchTerm) {
      state.activeSearchTerm = activeSearchTerm;
    },
    setActiveDossierStatus(state, activeDossierStatus) {
      state.activeDossierStatus = activeDossierStatus;
    },
    setActiveDossierResponsible(state, activeDossierResponsible) {
      state.activeDossierResponsible = activeDossierResponsible;
    },
    setActiveSpecialDossiersFilter(state, activeSpecialDossiersFilter) {
      state.activeSpecialDossiersFilter = activeSpecialDossiersFilter;
    },
    setActiveInsurer(state, activeInsurer) {
      state.activeInsurer = activeInsurer;
    },

    deleteFromStoreByStatus(_, status) {
      Dossier.delete(dossier => dossier.status === status);
    },

    setHasMoreDossiers(state, params) {
      state.hasMoreDossiers[params.status] = params.value;
    },
    setCursorForPagination(state, params) {
      state.cursorsForPagination[params.status] = params.cursor;
    },
    setTotalItems(state, params) {
      state.totalItems[params.status] = params.value;
    },
    setTotalFilteredItems(state, params) {
      state.totalFilteredItems[params.status] = params.value;
    },
    resetPaginationOptions(state, status) {
      if (status) {
        delete state.hasMoreDossiers[status];
        delete state.cursorsForPagination[status];
        delete state.totalItems[status];
        delete state.totalFilteredItems[status];
      } else {
        Object.keys(state.hasMoreDossiers).forEach(key => {
          delete state.hasMoreDossiers[key];
        });
        Object.keys(state.cursorsForPagination).forEach(key => {
          delete state.cursorsForPagination[key];
        });
        Object.keys(state.totalItems).forEach(key => {
          delete state.totalItems[key];
        });
        Object.keys(state.totalFilteredItems).forEach(key => {
          delete state.totalFilteredItems[key];
        });
      }
    },

    setHasInvalidDocuments(state, value) {
      state.hasInvalidDocuments = value;
      state.hasInvalidDocumentsToggle = !state.hasInvalidDocumentsToggle;
    },

    indicatePhaseValidationChanged(state) {
      state.phaseValidationChanged = !state.phaseValidationChanged;
    },

    setContactHasOnlyOneDossier(state, value) {
      state.contactHasOnlyOneDossier = value;
    },

    setAcceptedOfferPriceForDossier(_, payload) {
      Dossier.update({
        where: payload.dossierId,
        data: {
          dossierOffer: payload.dossierOffer,
        },
      });
    },
    setAverageTime(state, value) {
      state.averageTime = value;
    },

    setStatistics(state, value) {
      state.statistics = value;
    },
    setUrlInviteClient(state, value) {
      state.urlInviteClient = value;
    },
    setSubscriptionDataForCheckNotSave(state, value) {
      state.subscriptionNotSave = value;
    },
    setDataForUnsaveChanges(state, value) {
      state.subscriptionUnsaveChanges = value;
    },

    setActiveDossierNotificationStatus(state, activeDossierNotificationStatus) {
      state.activeDossierNotificationStatus = activeDossierNotificationStatus;
    },
    setActiveDossierNotificationActionType(state, activeDossierNotificationActionType) {
      state.activeDossierNotificationActionType = activeDossierNotificationActionType;
    },
    setActiveDossierNotificationPhase(state, activeDossierNotificationPhase) {
      state.activeDossierNotificationPhase = activeDossierNotificationPhase;
    },
    setClientIsActivated(state, value) {
      state.clientIsActivated = value;
    },
    setArchivedNotificationLog(state) {
      state.archivedNotificationLog = !state.archivedNotificationLog;
    },
    setController(state, controller) {
      state.controller = controller;
    },
    setLoadingStatistics(state, loadingStatistics) {
      state.loadingStatistics = loadingStatistics;
    },
  },
  actions: {
    getDossiersGroups({ commit }, payload) {
      return API.getDossiersGroups({
        search: payload.search,
        status: payload.status,
        responsible: payload.responsible,
        specialDossiersFilter: payload.specialDossiersFilter,
        insurer: payload.insurer,
      }).then(res => {
        for (const [status, data] of Object.entries(res.data)) {
          const { items, hasMore } = data;

          Dossier.insertOrUpdate({
            data: items,
          });

          const lastItemInResponse = items[items.length - 1];
          commit("setCursorForPagination", {
            status: status,
            cursor: lastItemInResponse,
          });

          commit("setHasMoreDossiers", { status, value: hasMore });
        }
      });
    },
    getDossiers({ getters, commit }, payload) {
      const cursorForPagination = getters.getCursorForPagination(payload.status);
      const cursorId = cursorForPagination ? cursorForPagination.id : undefined;
      const cursorOrderByValue = cursorForPagination
        ? cursorForPagination[payload.sortBy]
        : undefined;

      return API.getDossiers({
        search: payload.search,
        status: payload.status,
        responsible: payload.responsible,
        specialDossiersFilter: payload.specialDossiersFilter,
        insurer: payload.insurer,
        cursorId,
        cursorOrderByValue,
        sortBy: payload.sortBy,
        sortOrder: payload.sortOrder,
      }).then(res => {
        const { items, hasMore } = res.data;

        Dossier.insertOrUpdate({
          data: items,
        });

        const lastItemInResponse = items[items.length - 1];
        commit("setCursorForPagination", {
          status: payload.status,
          cursor: lastItemInResponse,
        });

        commit("setHasMoreDossiers", { status: payload.status, value: hasMore });
      });
    },
    countDossiers({ commit }, payload) {
      return API.countDossiers({
        search: payload.search,
        status: payload.status,
        responsible: payload.responsible,
        specialDossiersFilter: payload.specialDossiersFilter,
        insurer: payload.insurer,
      }).then(res => {
        const { count, total } = res.data;
        if (
          payload.search ||
          payload.responsible ||
          payload.specialDossiersFilter ||
          payload.insurer
        ) {
          commit("setTotalFilteredItems", { status: payload.status, value: count });
          commit("setTotalItems", { status: payload.status, value: total });
        } else {
          commit("setTotalItems", { status: payload.status, value: total });
        }
      });
    },

    resetPaginationOptions({ commit }, status) {
      commit("resetPaginationOptions", status);
    },

    getDossierByHash(_, payload) {
      return API.getDossierByHash(payload).then(res => {
        Dossier.insertOrUpdate({
          data: res.data,
        });
      });
    },
    updateDossierByHash(_, payload) {
      return new Promise((resolve, reject) => {
        API.updateDossierByHash(
          payload.hash,
          payload.dossier,
          payload.tarifer,
          payload.agencyCall,
          payload.changeEmailForThisDossier
        )
          .then(res => {
            Dossier.insertOrUpdate({
              data: res.data.dossier,
            });
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getDossier(_, payload) {
      return API.getDossier(payload.id).then(res => {
        Dossier.insertOrUpdate({
          data: res.data,
        });
      });
    },
    createDossier(_, payload) {
      return API.createDossier(payload.data, payload.agencyCall).then(response => {
        Dossier.insertOrUpdate({
          data: response.data.dossier,
        });
        return response;
      });
    },
    cancelDossier(_, payload) {
      return API.cancelDossier(payload.id, payload.data).then(res => {
        Dossier.insertOrUpdate({
          data: res.data,
        });
      });
    },
    rejectDossier(_, payload) {
      return API.rejectDossier(payload.id, payload.data).then(res => {
        Dossier.insertOrUpdate({
          data: res.data,
        });
      });
    },
    deleteDossier(_, payload) {
      return API.deleteDossier(payload.id, payload.data).then(res => {
        // HACK: status: "trashed"
        Dossier.insertOrUpdate({
          data: res.data,
        });
      });
    },
    hardDeleteDossier(_, payload) {
      return API.hardDeleteDossier(payload.id).then(() => {
        Dossier.delete(payload.id);
      });
    },
    restoreDossier(_, payload) {
      return API.restoreDossier(payload.id).then(res => {
        Dossier.insertOrUpdate({
          data: res.data,
        });
      });
    },
    updateAgencyCompany(_, payload) {
      return API.updateAgencyCompany(payload.id, payload.data).then(res => {
        Dossier.insertOrUpdate({
          data: res.data,
        });
      });
    },
    updateResponsible(_, payload) {
      return API.updateResponsible(payload.id, payload.data).then(res => {
        Dossier.insertOrUpdate({
          data: res.data,
        });
      });
    },
    updatePartner(_, payload) {
      return API.updatePartner(payload.id, payload.data).then(res => {
        Dossier.insertOrUpdate({
          data: res.data,
        });
      });
    },

    setActiveSearchTerm({ commit }, activeSearchTerm) {
      commit("setActiveSearchTerm", activeSearchTerm);
    },
    setActiveDossierStatus({ commit }, activeDossierStatus) {
      commit("setActiveDossierStatus", activeDossierStatus);
    },
    setActiveDossierResponsible({ commit }, activeDossierResponsible) {
      commit("setActiveDossierResponsible", activeDossierResponsible);
    },
    setActiveSpecialDossiersFilter({ commit }, activeSpecialDossiersFilter) {
      commit("setActiveSpecialDossiersFilter", activeSpecialDossiersFilter);
    },
    setActiveInsurer({ commit }, activeInsurer) {
      commit("setActiveInsurer", activeInsurer);
    },

    deleteFromStoreByStatus({ commit }, status) {
      commit("deleteFromStoreByStatus", status);
    },

    setAcceptedOfferPriceForDossier({ commit }, payload) {
      commit("setAcceptedOfferPriceForDossier", payload);
    },
    offer(_, payload) {
      return new Promise((resolve, reject) => {
        API.offer(payload)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    updateAPI({ commit }, payload) {
      return API.put(
        payload.id,
        payload.dossier,
        payload.tarifer,
        payload.changeEmailForThisDossier
      ).then(response => {
        commit("setSubscriptionDataForCheckNotSave", false);
        Dossier.insertOrUpdate({
          data: response.data,
        });
      });
    },
    updateNote(_, payload) {
      return API.updateNote(payload.id, payload.dossierNoteText).then(response => {
        Dossier.insertOrUpdate({
          data: response.data,
        });
      });
    },
    basicUpdate(_, payload) {
      return API.basicUpdate(payload.id, payload.data).then(response => {
        Dossier.insertOrUpdate({
          data: response.data,
        });
      });
    },
    updateSuspendCommunication(_, payload) {
      return API.updateSuspendCommunication(payload.id, payload.suspendCommunication).then(
        response => {
          Dossier.insertOrUpdate({
            data: response.data,
          });
        }
      );
    },

    contactOffer(_, payload) {
      return new Promise((resolve, reject) => {
        API.contactOffer(payload.hash, payload.data)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    contact(_, payload) {
      return new Promise((resolve, reject) => {
        API.contact(payload.hash, payload.data)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    offerRequest(_, payload) {
      return new Promise((resolve, reject) => {
        API.offerRequest(payload)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    offerRequestAgency(_, { dossierOfferId }) {
      return new Promise((resolve, reject) => {
        API.offerRequestAgency(dossierOfferId)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    checkInvalidDocuments({ commit }, dossierId) {
      return API.checkInvalidDocuments(dossierId).then(res => {
        commit("setHasInvalidDocuments", res.data.hasInvalid);
      });
    },
    setHasInvalidDocuments({ commit }, value) {
      commit("setHasInvalidDocuments", value);
    },

    indicatePhaseValidationChanged({ commit }) {
      commit("indicatePhaseValidationChanged");
    },

    checkContactHasOnlyOneDossier({ commit }) {
      return API.checkContactHasOnlyOneDossier().then(res => {
        commit("setContactHasOnlyOneDossier", res.data.hasOnlyOneDossier);
      });
    },

    generateDocument(_, payload) {
      return new Promise((resolve, reject) => {
        API.generateDocument(payload.dossierId, {
          rowId: payload.rowId,
          modelName: payload.modelName,
          documentKey: payload.documentKey,
          lotsExcluded: payload.lotsExcluded,
        })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    sendInviteDossier(_, payload) {
      return new Promise((resolve, reject) => {
        API.sendInviteDossier(payload)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    updateAdditionalFields(_, payload) {
      return API.updateAdditionalFields(payload.id, payload.data).then(res => {
        Dossier.insertOrUpdate({
          data: res.data,
        });
      });
    },

    statistics({ commit }, params) {
      return API.statistics(params).then(res => {
        commit("setAverageTime", {
          averageTime: res.data.averageTime,
          lastAverageTime: res.data.lastAverageTime,
        });
      });
    },
    triggerValidation(_, payload) {
      return new Promise((resolve, reject) => {
        API.triggerValidation(payload.dossierId, payload.phaseKey)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    removeContractNumber(_, payload) {
      return API.removeContractNumber(payload.id).then(res => {
        Dossier.insertOrUpdate({
          data: res.data,
        });
      });
    },
    deleteResponsiblePartner(_, payload) {
      return API.deleteResponsiblePartner(payload.id, payload).then(response => {
        Dossier.insertOrUpdate({
          data: response.data,
        });
      });
    },
    dossierStatistics({ commit, state }, params = {}) {
      commit("setLoadingStatistics", true);

      if (state.controller) {
        state.controller.abort();
      }

      const controller = new AbortController();
      commit("setController", controller);

      return API.getStatisticsForDossiers(params, controller.signal)
        .then(res => {
          commit("setStatistics", res.data);
          commit("setLoadingStatistics", false);
        })
        .catch(error => {
          if (error && error.message !== "canceled") {
            commit("setLoadingStatistics", false);
          }
        });
    },
    generateUrlForInvitationClient({ commit }, id) {
      return API.generateUrlForInvitationClient(id).then(res => {
        commit("setUrlInviteClient", res.data);
      });
    },
    updateInvalidDocumentsComment(_, payload) {
      return API.updateInvalidDocumentsComment(payload.id, payload.invalidDocumentsComment).then(
        response => {
          Dossier.insertOrUpdate({
            data: response.data,
          });
        }
      );
    },
    updateContractNumber(_, payload) {
      return API.updateContractNumber(payload.id, payload.data).then(res => {
        Dossier.insertOrUpdate({
          data: res.data,
        });
      });
    },
    updateLockedDossier(_, payload) {
      return API.updateLockedDossier(payload.id, payload.data).then(res => {
        Dossier.insertOrUpdate({
          data: res.data,
        });
      });
    },
    setSubscriptionDataForCheckNotSave({ commit }, value) {
      commit("setSubscriptionDataForCheckNotSave", value);
    },
    setDataForUnsaveChanges({ commit }, value) {
      commit("setDataForUnsaveChanges", value);
    },

    setActiveDossierNotificationStatus({ commit }, activeDossierStatus) {
      commit("setActiveDossierNotificationStatus", activeDossierStatus);
    },
    setActiveDossierNotificationActionType({ commit }, activeNotificationActionType) {
      commit("setActiveDossierNotificationActionType", activeNotificationActionType);
    },
    setActiveDossierNotificationPhase({ commit }, activeNotificationPhase) {
      commit("setActiveDossierNotificationPhase", activeNotificationPhase);
    },
    checkClientIsActivated({ commit }, id) {
      return API.checkClientIsActivated(id).then(res => {
        commit("setClientIsActivated", res.data);
      });
    },
    setArchivedNotificationLog({ commit }) {
      commit("setArchivedNotificationLog");
    },

    createBeneficiaries(_, payload) {
      return API.createBeneficiaries(payload.id, payload.beneficiary).then(res => {
        Dossier.insertOrUpdate({
          data: res.data,
        });
      });
    },
    updateBeneficiaries(_, payload) {
      return API.updateBeneficiaries(
        payload.id,
        payload.beneficiaries,
        payload.beneficiaryIndex,
        payload.isValidated,
        payload.deleteBeneficiaryUid
      ).then(res => {
        Dossier.insertOrUpdate({
          data: res.data,
        });
      });
    },
  },
  getters: {
    get: state => id => {
      return state.data[id] ? { ...state.data[id] } : null;
    },
    getHash: state => hash => {
      return Object.values(state.data).find(element => element.accessHash === hash);
    },
    getAll(state) {
      return Object.values(state.data).map(item => ({ ...item }));
    },

    getActiveSearchTerm(state) {
      return state.activeSearchTerm;
    },
    getActiveDossierStatus(state) {
      return state.activeDossierStatus;
    },
    getActiveDossierResponsible(state) {
      return state.activeDossierResponsible;
    },
    getActiveSpecialDossiersFilter(state) {
      return state.activeSpecialDossiersFilter;
    },
    getActiveInsurer(state) {
      return state.activeInsurer;
    },

    // ???: filter by search?
    getMultipleForGroupList:
      state =>
      (
        status,
        { searchTermFilter, responsible, specialDossiersFilter, insurer },
        sortBy = "updatedAt",
        sortOrder = "desc"
      ) => {
        return Object.values(state.data)
          .filter(dossier => {
            // Status
            const checkStatus = dossier.status === status;

            // Responsible
            let checkResponsible;
            if (
              responsible &&
              typeof responsible === "string" &&
              typeof responsible.includes("reception")
            ) {
              responsible = responsible.replace(" - reception", "");
              const comparableResponsibleId = responsible == -1 ? null : Number(responsible);
              checkResponsible =
                !responsible || dossier.secondResponsibleId === comparableResponsibleId;
            } else {
              const comparableResponsibleId = responsible == -1 ? null : Number(responsible);
              checkResponsible = !responsible || dossier.responsibleId === comparableResponsibleId;
            }

            // Insurer
            const comparableInsurerId = insurer == -1 ? null : Number(insurer);
            const checkInsurer = !insurer || dossier.insurerId === comparableInsurerId;

            // Special Dossiers Filter
            let appelPrimeUnpaid;
            let insurerReturnInValidation;
            let insurerReturnReceptionInValidation;
            if (specialDossiersFilter === specialDossiersFilters.APPEL_PRIME_UNPAID.key) {
              appelPrimeUnpaid = true;
            } else if (
              specialDossiersFilter === specialDossiersFilters.INSURER_RETURN_IN_VALIDATION.key
            ) {
              insurerReturnInValidation = true;
            } else if (
              specialDossiersFilter ===
              specialDossiersFilters.INSURER_RETURN_RECEPTION_IN_VALIDATION.key
            ) {
              insurerReturnReceptionInValidation = true;
            }
            const checkAppelPrimeUnpaid =
              !appelPrimeUnpaid || dossier.appelPrimeStatus === statusAppelPrime.UNPAID.key;
            const checkInsurerReturnInValidation =
              !insurerReturnInValidation || dossier.hasInsurerReturnInValidation;
            const checkInsurerReturnReceptionInValidation =
              !insurerReturnReceptionInValidation || dossier.hasFinalValidationInsurerInValidation;

            let checkSearchTerm = false;
            let searchTerm = searchTermFilter.toLowerCase();
            if (searchTerm.includes("do-")) {
              const searchTermId = searchTerm.replace("do-", "").replace(/^0+/, "");
              if (dossier.id == searchTermId) {
                checkSearchTerm = true;
              }
            } else {
              const searchContractNumber = searchTerm.toUpperCase();
              if (
                /^(ASSCO[0-9]{2}[0-9]+)$/.test(searchContractNumber) &&
                dossier.agencyCompany === agencyCompanies.MP.key &&
                dossier.documentNumber &&
                dossier.documentNumber.contractMp.includes(searchContractNumber)
              ) {
                checkSearchTerm = true;
              } else if (
                /^(PAULI[0-9]{2}[0-9]+)$/.test(searchContractNumber) &&
                dossier.agencyCompany === agencyCompanies.SAS.key &&
                dossier.documentNumber &&
                dossier.documentNumber.contractSas.includes(searchContractNumber)
              ) {
                checkSearchTerm = true;
              } else {
                const dossierFullname1 =
                  dossier.contactFirstname || dossier.contactLastname
                    ? dossier.contactFirstname + " " + dossier.contactLastname
                    : "";
                const dossierFullname2 =
                  dossier.contactFirstname || dossier.contactLastname
                    ? dossier.contactLastname + " " + dossier.contactFirstname
                    : "";
                const secondDossierFullname1 =
                  dossier.secondContactFirstname || dossier.secondContactLastname
                    ? dossier.secondContactFirstname + " " + dossier.secondContactLastname
                    : "";
                const secondDossierFullname2 =
                  dossier.secondContactFirstname || dossier.secondContactLastname
                    ? dossier.secondContactLastname + " " + dossier.secondContactFirstname
                    : "";
                if (
                  dossierFullname1.toLowerCase().includes(searchTerm) ||
                  dossierFullname2.toLowerCase().includes(searchTerm) ||
                  secondDossierFullname1.toLowerCase().includes(searchTerm) ||
                  secondDossierFullname2.toLowerCase().includes(searchTerm) ||
                  (dossier.contactCompanyName &&
                    dossier.contactCompanyName.toLowerCase().includes(searchTerm)) ||
                  (dossier.email && dossier.email.toLowerCase().includes(searchTerm)) ||
                  (dossier.contactPhone &&
                    dossier.contactPhone.toLowerCase().includes(searchTerm)) ||
                  (dossier.id && dossier.id.toString().includes(searchTerm))
                ) {
                  checkSearchTerm = true;
                }
                if (dossier.partnerId) {
                  const partner = User.query()
                    .where(user => user.id === Number(dossier.partnerId))
                    .first();

                  if (partner) {
                    const partnerFullname1 =
                      partner.firstname || partner.lastname
                        ? partner.firstname + partner.lastname
                        : "";
                    const partnerFullname2 =
                      partner.firstname || partner.lastname
                        ? partner.lastname + partner.firstname
                        : "";
                    if (
                      partnerFullname1.toLowerCase().includes(searchTerm) ||
                      partnerFullname2.toLowerCase().includes(searchTerm) ||
                      (partner.partnerKey && partner.partnerKey.toLowerCase().includes(searchTerm))
                    ) {
                      checkSearchTerm = true;
                    }
                  }
                }
              }
            }

            return (
              checkSearchTerm &&
              checkStatus &&
              checkResponsible &&
              checkAppelPrimeUnpaid &&
              checkInsurerReturnInValidation &&
              checkInsurerReturnReceptionInValidation &&
              checkInsurer
            );
          })
          .sort((a, b) => {
            // NOTE: NULLS FIRST
            const valueA = a[sortBy] || "";
            const valueB = b[sortBy] || "";
            if (valueA === valueB) {
              return a.id < b.id ? 1 : -1;
            } else if (sortOrder === "desc") {
              return valueA < valueB ? 1 : -1;
            } else {
              return valueA > valueB ? 1 : -1;
            }
          })
          .map(dossier => ({ ...dossier }));
      },

    getOneForDossierDetails: () => id => {
      return Dossier.query()
        .whereId(Number(id))
        .with("insurer|responsible|partner|secondResponsible")
        .first();
    },

    offerDetail(_, payload) {
      return API.offerDetail(payload);
    },

    getHasMoreDossiers: state => status => {
      return !!state.hasMoreDossiers[status];
    },
    getCursorForPagination: state => status => {
      return state.cursorsForPagination[status];
    },
    getTotalItems: state => status => {
      return state.totalItems[status];
    },
    getTotalFilteredItems: state => status => {
      return state.totalFilteredItems[status];
    },

    getHasInvalidDocuments(state) {
      return state.hasInvalidDocuments;
    },
    getHasInvalidDocumentsToggle(state) {
      return state.hasInvalidDocumentsToggle;
    },

    getPhaseValidationChanged(state) {
      return state.phaseValidationChanged;
    },

    getContactHasOnlyOneDossier(state) {
      return !!state.contactHasOnlyOneDossier;
    },
    getAverageTime(state) {
      return state.averageTime;
    },
    getTotalStatistics(state) {
      return state.statistics.total;
    },
    getStatisticsByStatus: state => status => {
      return Object.values(state.statistics.dossierStatus)
        .filter(item => item.status === status)
        .map(item => ({ ...item }));
    },
    getTotalLeadStatistics(state) {
      return state.statistics.totalLead;
    },
    getDossierStatusIsSubscribedOrFinalized() {
      const dossier = Dossier.query().first();
      const isSubscribedOrFinalized = [statuses.FINALIZED.key, statuses.SUBSCRIBED.key].includes(
        dossier.status
      );
      if (isSubscribedOrFinalized) {
        return true;
      }
      return false;
    },
    getUrlInviteDossier(state) {
      return state.urlInviteClient;
    },
    getDossierStatusIsReceptionFinalizedSubscribed() {
      const dossier = Dossier.query().first();
      const isSubscribedOrFinalized = [
        statuses.FINALIZED.key,
        statuses.RECEPTION.key,
        statuses.SUBSCRIBED.key,
      ].includes(dossier.status);
      if (
        isSubscribedOrFinalized &&
        dossier.phasesStatuses[phasesNames.PHASE_6.key].status === phaseValidationStatus.VALID.key
      ) {
        return true;
      }
      return false;
    },

    getSubscriptionNotSave(state) {
      return state.subscriptionNotSave;
    },
    getSubscriptionUnsaveChanges(state) {
      return state.subscriptionUnsaveChanges;
    },

    getActiveDossierNotificationStatus(state) {
      return state.activeDossierNotificationStatus;
    },
    getActiveDossierNotificationActionType(state) {
      return state.activeDossierNotificationActionType;
    },
    getActiveDossierNotificationPhase(state) {
      return state.activeDossierNotificationPhase;
    },
    getclientIsActivated(state) {
      return state.clientIsActivated;
    },
    getArchivedNotificationLog(state) {
      return state.archivedNotificationLog;
    },
    getLoadingStatistics(state) {
      return state.loadingStatistics;
    },
  },
};
